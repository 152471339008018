<template>
    <div id="wrapper" class="bg-white pt-4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-4 offset-xl-4 col-lg-4 col-md-5 offset-lg-4 offset-md-2">
                    <div class="d-flex">
                        <div class="text-left p-4 pb-2">
                            <h3 class="text-uppercase text-orange fw-bold mb-1 mt-2" style="letter-spacing: 1px">Register</h3>
                            <p class="mb-2">into Gear Health System</p>                           
                        </div>
                    </div>

                    <status v-model:status_data=status_data></status>

                    <form id="form" action="#" @submit.prevent="handleSubmit()">
                        <div class="card-body border-bottom-0 p-4">
                            <div class="form-row">
                                <div class="input-group">
                                    <span class="input-group-text border-right-0">
                                        <font-awesome-icon id="eye" :icon="['fa', 'user']" />
                                    </span>

                                    <input id="names" type="text" class="form-control no-border" v-model="data.name" placeholder="Enter your Names" required> 
                                </div>
                            </div>
                            
                            
                            <div class="form-row mt-4">
                                <div class="input-group">
                                    <span class="input-group-text border-right-0" @click="seePassword()">
                                        <font-awesome-icon id="eye" :icon="['fa', 'envelope']" />
                                    </span>
                                    
                                    <input id="email" type="email" class="form-control no-border" v-model="data.email" placeholder="Enter your Email" required>
                                </div>
                            </div>

                            <div class="form-row mt-4">
                                <div class="input-group">
                                    <span class="input-group-text border-right-0" @click="seePassword()">
                                        <font-awesome-icon id="eye" :icon="['fa', 'phone-alt']" />
                                    </span>
                                    
                                    <input id="email" type="number" class="form-control no-border" v-model="data.phone_number" placeholder="Enter your Phone Number" required>
                                </div>
                            </div>                                

                            <div class="row mt-4" id="password-row">
                                <div class="input-group">
                                    <span class="input-group-text" >
                                        <font-awesome-icon id="lock" :icon="['fa', 'lock']" />
                                    </span>

                                    <input id="password" type="password" class="form-control" v-model="data.password" placeholder="Create Password" required>
                                    
                                    <span class="input-group-text pe-4" @click="seePassword('password')">
                                        <font-awesome-icon id="eye" :icon="['fa', 'eye']" />
                                    </span>
                                </div>
                            </div>

                            <div class="row mt-4" id="cpassword-row">
                                <div class="input-group">
                                    <span class="input-group-text" >
                                        <font-awesome-icon id="lock" :icon="['fa', 'lock']" />
                                    </span>

                                    <input id="cpassword" type="password" class="form-control" v-model="data.cpassword" placeholder="Confirm Password" required>
                                    
                                    <span class="input-group-text pe-4" @click="seePassword('cpassword')">
                                        <font-awesome-icon id="eye" :icon="['fa', 'eye']" />
                                    </span>
                                </div>
                            </div>

                            <div class="form-row mt-4">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" required>
                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                    I have read and accepted the <a class="text-orange text-decoration-none" href="https://gearhealthsystem.com/terms" target="_blank">Terms and Conditions</a>
                                </label>
                            </div>

                            <div class="row" id="login">
                                <div class="col">
                                    <button class="btn bg-orange text-white  btn-sm mt-4 w-100 text-uppercase fw-bold pt-2 pb-2" type="submit">
                                        <span style="letter-spacing: 2px">Register</span> <font-awesome-icon class="icon ms-1 me-1" :icon="['fa', 'unlock']" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>  
            </div>
        </div>

        <div class="container-fluid">
            <p class="text-center pt-4">Have an account? <router-link :to="'/login'" class="text-orange text-decoration-none">Login!</router-link></p>
        </div>

        <main-footer :center=true></main-footer>
    </div>
</template>

<style scoped lang="scss">
    @import '../../assets/scss/base/_base.scss';
    @import '../../assets/scss/base/_fonts.scss';
    @import '../../assets/scss/helpers/breakpoints.scss';

    .card {
        box-shadow: 0 4px 8px rgb(0 0 0 / 3%)
    }
    
</style>

<script>
    import Status from '../../components/Status';
    import MainFooter from '../../components/Footer';

    export default {
        name: 'Login',
        props: {
            email: {
                required: true,
                type: String
            },
            supplier_id: {
                required: true,
                type: String
            }
        },
        components: {
            Status,
            MainFooter
        },
        data() {
            return {
                data: {
                    name: null,
                    email: this.email,
                    phone_number: null,
                    password: null,
                    cpassword: null,
                    verified: true,
                    supplier_id: this.supplier_id,

                },
                status_data: {
                    "success": false,
                    "success_message": 'Registration Successful!',
                    "error": false,
                    "error_message": 'There are some input errors.',
                },
                loading: false
            }
        },
        methods: {
            handleSubmit() {
                this.status_data.loading = true
                let validatePassword = this.validatePassword()

                if(validatePassword == true) {
                    this.axios.post('api/register', this.data).then(() => {
                        this.status_data.loading = false
                        this.status_data.success = true
                        this.status_data.error = false

                        setTimeout(() => {
                            this.$router.push({name: 'Login'});
                        }, 2000);
                    }).catch((error) => {
                        this.status_data.loading = false
                        this.status_data.success = false
                        this.status_data.error = true

                        this.status_data.error_message = error.response.data.data
                    })   
                } else {
                    this.status_data.loading = false
                    this.status_data.success = false
                    this.status_data.error = true

                    this.status_data.error_message = validatePassword.message                 
                }
            }, 
            validatePassword() {
                if(this.data.password == this.data.cpassword) {
                    if(this.data.password.length > 7) {
                        return true
                    } else {
                        return {
                            status: false,
                            message: "Password length is too short."
                        }
                    }
                } else {
                    return {
                        status: false,
                        message: "Your passwords do not match."
                    }                    
                }
            },
            seePassword(id) {
                if(document.querySelector('#' + id).type == 'password') {
                    document.querySelector('#' + id).type = 'text'
                } else {
                    document.querySelector('#' + id).type = 'password'
                }
            },
            hideAlert() {
                if(this.success == true) {
                    this.success = false
                } else if(this.error == true) {
                    this.error = false
                }
            }
        }
    }
</script>